import { combineReducers } from "redux";
import authReducer from "./auth";
import invpost from "./invoicepost"
import custpost from   "./custmoerpost"
import paypost from "./paymentpost"


export default combineReducers({
    authState:authReducer,
    invoice:invpost,
    custs:custpost,
    pay:paypost
    
})