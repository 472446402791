import { Button, Menu, Avatar } from 'antd';
import { HomeOutlined, UserOutlined, ContactsOutlined } from '@ant-design/icons';
import { MailOutlined, AppstoreOutlined, SettingOutlined,DashboardOutlined } from '@ant-design/icons';
import { Routes, Route, Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ProtectedRoute from '../component/Protectrouter';
import { Redirect, useHistory } from 'react-router-dom';
const { SubMenu } = Menu;

const Header = () => {

	const authState = useSelector(state => state.authState)
	let userId = localStorage.getItem('userid');
	let Username = localStorage.getItem('profileName')
	const history = useHistory();
	const signout = () => {

		localStorage.removeItem('userid');
		localStorage.removeItem('profileName');
	}

	if (userId == null) {

	    let path = "/Login";
	    history.push(path);
	}

	return (
		<div>
			<div className='navlinkdiv'>

				<Menu mode="horizontal" theme="dark">
					
					<SubMenu key="sub1" icon={<Avatar size="small" src={require("../assets/Images/1.jpg")} />} title={Username}>
						<Menu.Item key="Edituserinfo">
							<NavLink to="/Edituserinfo">Edit Profile</NavLink>
						</Menu.Item>
						<Menu.Item key="category2">
							<NavLink to="/category2" onClick={signout}>Log Out</NavLink>
						</Menu.Item>
					</SubMenu>
					<Menu.Item key="Dashbord">
						<NavLink to="/Dashbord"><HomeOutlined/></NavLink>
					</Menu.Item>
					<Menu.Item key="investment">
						<NavLink to="/investment" activeClassName="active">Investment</NavLink>
					</Menu.Item>
					{/* <Menu.Item key="Payment">
						<NavLink to="/Payment">Payment</NavLink>
					</Menu.Item> */}
					{/* <Menu.Item key="ContactPage">
						<NavLink to="/ContactPage">ContactPage</NavLink>
					</Menu.Item> */}
					<SubMenu title="Administrator">
					<Menu.Item key="investmentupdate">
						<NavLink to="/investmentupdate">Active Member</NavLink>
					</Menu.Item>
					<Menu.Item key="Invtprofitreturn">
						<NavLink to="/Invtprofitreturn">Return Profit</NavLink>
					</Menu.Item>
					<Menu.Item key="Convertdata">
						<NavLink to="/Convertdata">Convert data</NavLink>
					</Menu.Item>
					
					</SubMenu>

					{/* <Menu.Item key="Newspage">
						<NavLink to="/Newspage">News</NavLink>
					</Menu.Item> */}

					<Menu.Item key="Aboutus">
						<NavLink to="/Aboutus">Aboutus</NavLink>
					</Menu.Item>
					{/* <Menu.Item key="Ourservices">
						<NavLink to="/Ourservices">Ourservices</NavLink>
					</Menu.Item> */}
					<Menu.Item key="Calculator">
						<NavLink to="/Calculator ">Calulater</NavLink>
					</Menu.Item>
					<Menu.Item key="Faq">
						<NavLink to="/Faq">FAQ</NavLink>
					</Menu.Item>

				</Menu>
			</div>
		</div>
	)


}
export default Header