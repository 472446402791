import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';



const initialState = {
    invoiceid: null,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const Invoicepost = (state, action) => {
    return mergObject(state, {
        invoiceid: action.token,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}

const editinvoicedetail = (state, action) => {
    return mergObject(state, {
        invoiceid: action.token,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}

const deleteinvoicedetail = (state, action) => {
    return mergObject(state, {
        invoiceid: action.token,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}


const reducer = (state=initialState,action) => 
{
    switch (action.type) {
        case actiontype.INVOICE_POST:return Invoicepost(state, action) 
        case actiontype.EDIT_INVOICEDETAIL:return editinvoicedetail(state, action) 
        case actiontype.DELETE_INVOICEDETAIL:return deleteinvoicedetail(state, action)       
        default: return state;
    }    
}

export default reducer;