import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    token: null,
    userid: null,
    username :null,
    auth : null,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const loginSuccess = (state, action) => {
    return mergObject(state, {
        token: action.token,
        userid: action.userid,
        username:action.username,
        auth:action.auth,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}



const logoutSuccess = (state, action) => {
    return mergObject(state, {
        token: null,
        userid: null,
        username:null,
        error: null,
        isloading: false,
        isLoggedIn: false
    });
}
const reducer = (state=initialState,action) => 
{
    switch (action.type) {
        case actiontype.LOGIN_START:return mergObject(state,{error: null,isloading: true})
        case actiontype.LOGIN_SUCCESS:return loginSuccess(state, action)
        case actiontype.LOGIN_FAIL:return mergObject(state,{error:action.error,isloading: false})
        case actiontype.LOGOUT_SUCCESS:return logoutSuccess(state, action)    
        default: return state;
    }    
}

export default reducer;