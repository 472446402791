// import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Row, Col } from 'antd'
const RedirectPage = () => {
    const [count, setCount] = useState(30); // Initial count value

    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (count === 30) {
                history.push('/Indexpage'); // Redirect to index page
            } else {
                setCount(count - 1); // Decrement the count
            }
        }, 1000); // 1000 milliseconds = 1 second

        return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
    }, [count, history]);

    return (
        <div>
            <div>
                <Row className='pagediv'>
                    <div className='copanyinformation'>
                        <div className='quats'>
                            <p >“ The biggest risk is not taking a risk. In a world that’s changing really quickly,
                                the only strategy that is guaranteed to fail is not taking risks. ”
                            </p>
                            <p style={{ fontStyle: "italic", color: "lightgreen"}}>– Mark Zuckerberg</p>
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    );
};

export default RedirectPage;
