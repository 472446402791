import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    invoiceid: null,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const Paymentpost = (state, action) => {
    return mergObject(state, {
        invoiceid: action.token,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}


const reducer = (state=initialState,action) => 
{
    switch (action.type) {
        case actiontype.PAYMENT_POST:return Paymentpost(state, action)   
        default: return state;
    }    
}

export default reducer;