import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    custmerid: null,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const Custmorepost = (state, action) => {
    return mergObject(state, {
        custmerid: action.token,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}


const reducer = (state=initialState,action) => 
{
    switch (action.type) {
        case actiontype.NEW_CUSTOMER_POST:return Custmorepost(state, action)   
        default: return state;
    }    
}

export default reducer;