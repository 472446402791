export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL =' LOGOUT_FAIL';

export const INVOICE_POST = 'INVOICE_POST';

export const NEW_CUSTOMER_POST = 'NEW_CUSTOMER_POST';

export const PAYMENT_POST = 'PAYMENT_POST';

export const CREATE_OTP_EMAIL = 'CREATE_OTP_EMAIL';

export const CHECK_OTP_EMAIL ='CHECK_OTP_EMAIL';

export const CHECK_EMAIL = 'CHECK_EMAIL';

export const REG_START = 'REG_START';

export const REG_SUCCESS = 'REG_SUCCESS';

export const REG_FAIL = 'REG_FAIL';

export const USERPROFILEEDIT_START = 'USERPROFILEEDIT_START'
export const USERPROFILEEDIT_SUCCESS = 'USERPROFILEEDIT_SUCCESS'
export const USERPROFILEEDIT_FAIL = 'USERPROFILEEDIT_FAIL'

export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD'

export const EDIT_INVOICEDETAIL = "EDIT_INVOICEDETAIL"

export const DELETE_INVOICEDETAIL = "DELETE_INVOICEDETAIL"


