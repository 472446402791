
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Layout from './layout';
import '../assets/css/index.css';
import { useDispatch, useSelector } from "react-redux";
// import Indexpage from "../component/Indexpage"
import companypage from "../component/companypage"

const Indexpage = React.lazy(() => import('../component/Indexpage'));
const Login = React.lazy(() => import('../component/Login'));
const Edituser = React.lazy(() => import('../component/Edituserinfo'));
const UserPage = React.lazy(() => import('../component/UserPage'));
const Investment = React.lazy(() => import('../component/investment'));
const Custmoerinfo = React.lazy(() => import('../component/Custmoerinfo'));
const Payment = React.lazy(() => import('../component/Payment'));
const ContactPage = React.lazy(() => import('../component/ContactPage'));
const Dashbord = React.lazy(() => import('../component/Dashbord'));
const Investmentupdate = React.lazy(() => import('../component/investmentupdate'));
const Forgotpassword = React.lazy(() => import('../component/Forgotpassword'));
const Invtprofitreturn = React.lazy(() => import('../component/Invtprofitreturn'));
const Newspage = React.lazy(() => import('../component/News'));
const Aboutus = React.lazy(() => import('../component/Aboutus'));
const Ourservices  = React.lazy(() => import('../component/Ourservices'));
const Calculator  = React.lazy(() => import('../component/Calculator '));
const Faq = React.lazy(() => import('../component/Faq'));
const Privacypolicy = React.lazy(() => import('../component/Privacypolicy'));
const Convertdata = React.lazy(() => import('../component/convertdata'));

const fallback = <p>....loading</p>

const App = () => {
    const authState = useSelector(state => state.authState)
    let routes = (
        <Switch>            
            <Route path="/" exact component={companypage} />
            <Route path="/Indexpage" render={() => (<Suspense fallback={fallback}><Indexpage /></Suspense>)} />
            <Route path="/Login" render={() => (<Suspense fallback={fallback}><Login /></Suspense>)} />
            <Route path="/Edituserinfo" render={() => (<Suspense fallback={fallback}><Edituser /></Suspense>)} />
            <Route path="/Investment" render={() => (<Suspense fallback={fallback}><Investment /></Suspense>)} />
            <Route path="/Custmoerinfo" render={() => (<Suspense fallback={fallback}><Custmoerinfo /></Suspense>)} />
            <Route path="/Payment" render={() => (<Suspense fallback={fallback}><Payment /></Suspense>)} />
            <Route path="/UserPage" render={() => (<Suspense fallback={fallback}><UserPage /></Suspense>)} />
            <Route path="/ContactPage" render={() => (<Suspense fallback={fallback}><ContactPage /></Suspense>)} />
            <Route path="/Dashbord" render={() => (<Suspense fallback={fallback}><Dashbord /></Suspense>)} />
            <Route path="/Investmentupdate" render={() => (<Suspense fallback={fallback}><Investmentupdate /></Suspense>)} />
            <Route path="/Forgotpassword" render={() => (<Suspense fallback={fallback}><Forgotpassword /></Suspense>)} />
            <Route path="/Invtprofitreturn" render={() => (<Suspense fallback={fallback}><Invtprofitreturn /></Suspense>)} />
            <Route path="/Newspage" render={() => (<Suspense fallback={fallback}><Newspage /></Suspense>)} />
            <Route path="/Aboutus" render={() => (<Suspense fallback={fallback}><Aboutus /></Suspense>)} />
            <Route path="/Ourservices " render={() => (<Suspense fallback={fallback}><Ourservices /></Suspense>)} />
            <Route path="/Calculator " render={() => (<Suspense fallback={fallback}><Calculator/></Suspense>)} />
            <Route path="/Faq" render={() => (<Suspense fallback={fallback}><Faq/></Suspense>)} />
            <Route path="/Privacypolicy" render={() => (<Suspense fallback={fallback}><Privacypolicy/></Suspense>)} />
            <Route path="/Convertdata" render={() => (<Suspense fallback={fallback}><Convertdata/></Suspense>)} />
            <Redirect to="/" />
        </Switch>
    );
    return (
        <div className="App" >
            <Layout>{routes}</Layout>
        
           
        </div>
    );
}

export default withRouter(App);
